import Box from "@mui/material/Box";
import { useState } from "react";

import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
} from "react-admin";

import SubMenu from "./layout/SubMenu";
import admins from "./pages/Admin";
import loginLogs from "./pages/LoginLog";
import users from "./pages/User";
import userWallets from "./pages/user/UserWallet";
import protocols from "./pages/coin/Protocol";
import coins from "./pages/coin/Coin";
// import coinProtocols from "./pages/coin/CoinProtocol";
import exchangeCoins from "./pages/exchange/ExchangeCoin";
import exchangeOrders from "./pages/exchange/ExchangeOrder";
import exchangeTrades from "./pages/exchange/ExchangeTrade";
import { icon as ExchangeMarginIcon } from "./pages/exchange/ExchangeMargin";
import robotLines from "./pages/exchange/RobotLine";

import docs from "./pages/content/Doc";
import webPages from "./pages/content/WebPage";
import docCategories from "./pages/content/DocCategory";
import settings from "./pages/setting/setting";
import zones from "./pages/exchange/Zone";
import { IS_ROBOT } from "./config";
import nftCategories from "./pages/nft/NftCategory";
import nftGoods from "./pages/nft/NftGoods";
import nftOrders from "./pages/nft/NftOrder";

import fundTermPlans from "./pages/fund/FundTermPlans";
import fundTermUsers from "./pages/fund/FundTermUser";
import fundCurrentPlans from "./pages/fund/FundCurrentPlans";
import fundCurrentUsers from "./pages/fund/FundCurrentUser";
import { EmailSetting } from "./pages/setting/EmailSetting";
import MySecurity from "./pages/setting/MySecurity";

type MenuName =
  | "menuOrder"
  | "menuExchange"
  | "menuCoin"
  | "menuCatalog"
  | "menuUsers"
  | "menuAdmins"
  | "menuContent";

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuUsers: true,
    menuExchange: true,
    menuAdmins: true,
    menuSetting: true,
    menuContent: true,
    menuOrder: true,
    menuCoin: true,
  });
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  if (IS_ROBOT) {
    return (
      <Box
        sx={{
          width: open ? 200 : 50,
          marginTop: 1,
          marginBottom: 1,
          transition: (theme) =>
            theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        <SubMenu
          handleToggle={() => handleToggle("menuExchange")}
          isOpen={state.menuExchange}
          name="控线机器人"
          icon={<exchangeCoins.icon />}
          dense={dense}
        >
          <MenuItemLink
            to="/robotLines"
            state={{ _scrollToTop: true }}
            primaryText={"控线机器人"}
            leftIcon={<robotLines.icon />}
            dense={dense}
          />
        </SubMenu>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />
      {/*<SubMenu*/}
      {/*  handleToggle={() => handleToggle("menuContent")}*/}
      {/*  isOpen={state.menuContent}*/}
      {/*  name="内容"*/}
      {/*  icon={<users.icon />}*/}
      {/*  dense={dense}*/}
      {/*></SubMenu>*/}
      <SubMenu
        handleToggle={() => handleToggle("menuUsers")}
        isOpen={state.menuUsers}
        name="会员管理"
        icon={<users.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/users"
          state={{ _scrollToTop: true }}
          primaryText={"会员管理"}
          leftIcon={<users.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/userByGroup"
          state={{ _scrollToTop: true }}
          primaryText={"会员组管理"}
          leftIcon={<users.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/userWallets"
          state={{ _scrollToTop: true }}
          primaryText={"钱包管理"}
          leftIcon={<userWallets.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/authKey"
          state={{ _scrollToTop: true }}
          primaryText={"2FA 绑定"}
          leftIcon={<MySecurity.icon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuOrder")}
        isOpen={state.menuOrder}
        name="订单管理"
        icon={<exchangeOrders.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/exchangeMarginsLeverage"
          state={{ _scrollToTop: true }}
          primaryText={"杠杆仓位"}
          leftIcon={<ExchangeMarginIcon sx={{ color: "#3ac181" }} />}
          dense={dense}
        />
        <MenuItemLink
          to="/exchangeMarginsContract"
          state={{ _scrollToTop: true }}
          primaryText={"合约仓位"}
          leftIcon={<ExchangeMarginIcon sx={{ color: "#f74a4c" }} />}
          dense={dense}
        />
        <MenuItemLink
          to="/exchangeOrders"
          state={{ _scrollToTop: true }}
          primaryText={"交易订单"}
          leftIcon={<exchangeOrders.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/nftOrders"
          state={{ _scrollToTop: true }}
          primaryText={"NFT订单"}
          leftIcon={<nftOrders.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/fundTermUsers"
          state={{ _scrollToTop: true }}
          primaryText={"基金持仓"}
          leftIcon={<fundTermUsers.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/fundCurrentUsers"
          state={{ _scrollToTop: true }}
          primaryText={"活期持仓"}
          leftIcon={<fundCurrentUsers.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/nftOrders"
          state={{ _scrollToTop: true }}
          primaryText={"NFT订单"}
          leftIcon={<nftOrders.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/exchangeTrades"
          state={{ _scrollToTop: true }}
          primaryText={"成交信息管理"}
          leftIcon={<exchangeTrades.icon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuExchange")}
        isOpen={state.menuExchange}
        name="币币交易"
        icon={<exchangeCoins.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/robotLines"
          state={{ _scrollToTop: true }}
          primaryText={"控线机器人"}
          leftIcon={<robotLines.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/exchangeCoins"
          state={{ _scrollToTop: true }}
          primaryText={"交易对管理"}
          leftIcon={<exchangeCoins.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/zones"
          state={{ _scrollToTop: true }}
          primaryText={"交易区域"}
          leftIcon={<zones.icon />}
          dense={dense}
        />
      </SubMenu>

      <SubMenu
        handleToggle={() => handleToggle("menuCoin")}
        isOpen={state.menuCoin}
        name="币种管理"
        icon={<coins.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/coins"
          state={{ _scrollToTop: true }}
          primaryText={"币种管理"}
          leftIcon={<coins.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/protocols"
          state={{ _scrollToTop: true }}
          primaryText={"协议网络"}
          leftIcon={<protocols.icon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuAdmins")}
        isOpen={state.menuAdmins}
        name="内容管理"
        icon={<docs.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/docs"
          state={{ _scrollToTop: true }}
          primaryText={"文章管理"}
          leftIcon={<docs.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/docCategories"
          state={{ _scrollToTop: true }}
          primaryText={"文章分类"}
          leftIcon={<docCategories.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/webPages"
          state={{ _scrollToTop: true }}
          primaryText={"页面信息"}
          leftIcon={<webPages.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/fundCurrentPlans"
          state={{ _scrollToTop: true }}
          primaryText={"活期设置"}
          leftIcon={<fundCurrentPlans.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/fundTermPlans"
          state={{ _scrollToTop: true }}
          primaryText={"基金设置"}
          leftIcon={<fundTermPlans.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/nftCategories"
          state={{ _scrollToTop: true }}
          primaryText={"NFT分类"}
          leftIcon={<nftCategories.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/nftGoods"
          state={{ _scrollToTop: true }}
          primaryText={"NFT商品"}
          leftIcon={<nftGoods.icon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuAdmins")}
        isOpen={state.menuSetting}
        name="系统设置"
        icon={<settings.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/settings/ExchangeSetting"
          state={{ _scrollToTop: true }}
          primaryText={"交易设置"}
          leftIcon={<settings.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/settings/SiteSetting"
          state={{ _scrollToTop: true }}
          primaryText={"站点设置"}
          leftIcon={<settings.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/settings/BinanceSetting"
          state={{ _scrollToTop: true }}
          primaryText={"币安设置"}
          leftIcon={<settings.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/settings/EmailSetting"
          state={{ _scrollToTop: true }}
          primaryText={"邮件推送设置"}
          leftIcon={<settings.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/telBotConfigs"
          state={{ _scrollToTop: true }}
          primaryText={"电报机器人配置"}
          leftIcon={<settings.icon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuAdmins")}
        isOpen={state.menuAdmins}
        name="管理员"
        icon={<admins.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/admins"
          state={{ _scrollToTop: true }}
          primaryText={"管理员"}
          leftIcon={<admins.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/loginLogs"
          state={{ _scrollToTop: true }}
          primaryText={"登录记录"}
          leftIcon={<loginLogs.icon />}
          dense={dense}
        />
      </SubMenu>
    </Box>
  );
};

export default Menu;
